import React, { useState } from 'react'
import { StyledSelect } from '../CustomStyles'
import { toast } from'react-hot-toast';
import { putData } from '../../redux/apiUtils'; 

function RoleChange({ user }) {

    const [enterRole, setEnterRole] = useState(user.role);

    const handleChange = async (event) => {
        const newRole = event.target.value;
        setEnterRole(newRole);
    
        try {
            // Call putData to update the role
            const response = await putData('users/update-role', { userid: user.id, role: newRole });
    
            // If successful, notify the user
            toast.success('Role status updated');
            console.log(response.message); // Assuming response has a message field
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to update role status');
        }
    };
    return (

        <td>
            <StyledSelect type="number" id="urole" placeholder="Enter the role" value={enterRole} onChange={handleChange} required >
                <option value="">Select Category</option>
                <option value="vendor">Vendor</option>
                <option value="customer">Customer</option>
                <option value="admin">Admin</option>
            </StyledSelect>
        </td>

    )
}

export default RoleChange