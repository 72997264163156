import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Loader from '../../components/Loader/Loader';

const ProtectedRoute = ({ allowedRoles }) => {
  //console.log("Allowed Roles:", allowedRoles);
  const { user, loading } = useAuth(); // Destructure user and loading

  //console.log("User:", user);
  // Show a loading indicator while user data is being fetched
  if (loading) {
    return <Loader />;
  }

  // Check if the user is authenticated
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Check if the user's role is allowed
  if (!allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }

  // Render the child components if all checks pass
  return <Outlet />;
};

export default ProtectedRoute;
