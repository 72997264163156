import React, { useRef } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import './styles.scss'; // Import the SCSS file
import useGetData from '../../hooks/useGetData'; // Adjust the import path as needed
import Loader from '../../components/Loader/Loader';

const Testimonial = () => {
  const { data: testimonials, loading } = useGetData('home/testimonials');
  
  // Create a reference to the OwlCarousel instance
  const owlRef = useRef(null);

  // Function to go to the previous item
  const goToPrev = () => {
    if (owlRef.current) {
      owlRef.current.prev(); // Check if this method exists
    }
  };

  // Function to go to the next item
  const goToNext = () => {
    if (owlRef.current) {
      owlRef.current.next(); // Check if this method exists
    }
  };

  if (loading) return <Loader/>;
  if (!testimonials || testimonials.length === 0) return <div>No testimonials available</div>;

  return (
    <div className="container-fluid testimonial py-5">
      <div className="container py-5">
        <div className="testimonial-header text-center mb-5">
          <h4 className="text-primary">Our Testimonial</h4>
          <h1 className="display-5 text-dark">Our Clients Saying!</h1>
        </div>
        <div className="carousel-container position-relative">
          <OwlCarousel 
            className="owl-carousel testimonial-carousel" 
            loop={true} // Ensure looping is enabled
            margin={10} 
            nav={false} // Disable default nav
            items={3} // Number of items to show
            autoplay={true} // Enable autoplay
            autoplayTimeout={2000} // Time between slides in milliseconds
            autoplayHoverPause={true} // Pause on hover
            ref={owlRef} // Attach the ref
            responsive={{
              0: {
                items: 1, // Show 1 item on small screens
              },
              600: {
                items: 2, // Show 2 items on medium screens
              },
              1000: {
                items: 3, // Show 3 items on large screens
              }
            }}
          >
            {testimonials.map((item, index) => {
              // Construct the full image URL
             
              const imagePath = item.imageSrc;
             
              const imageUrl = imagePath
              ? (imagePath.startsWith('http://') || imagePath.startsWith('https://') 
                ? imagePath
                : process.env.PUBLIC_URL + '/' + imagePath)
              : '';
              return (
                <div key={index} className="testimonial-item img-border-radius bg-light rounded p-4">
                  <div className="position-relative">
                    <i
                      className="fa fa-quote-right fa-2x position-absolute"
                      style={{ bottom: '30px', right: 0 }}
                    ></i>
                    <div className="mb-4 pb-4 border-bottom border-secondary">
                      <p className="mb-0">{item.text}</p>
                    </div>
                    <div className="d-flex align-items-center flex-nowrap">
                      <div className="bg-secondary rounded">
                        <img
                          src={imageUrl} // Use the dynamic image URL
                          className="img-fluid rounded"
                          style={{ width: '100px', height: '100px' }}
                          alt={item.name}
                        />
                      </div>
                      <div className="ms-4 d-block">
                        <h4 className="text-dark">{item.name}</h4>
                        <p className="m-0 pb-3">{item.profession}</p>
                        <div className="d-flex pe-5">
                          {[...Array(5)].map((star, i) => (
                            <i
                              key={i}
                              className={`fas fa-star ${i < item.rating ? 'text-primary' : ''}`}
                            ></i>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
          {/* Custom navigation controls */}
          <div className="custom-nav-left" onClick={goToPrev}>
            <i className="bi bi-arrow-left"></i>
          </div>
          <div className="custom-nav-right" onClick={goToNext}>
            <i className="bi bi-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
