import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories, updateCategories } from '../../../redux/thunks/categoryThunks'; 
import { setSelectedCategory } from '../../../redux/slices/categorySlice'; 
import './CategoryInventory.scss';
import ProductRow from './ProductRow';
import { toast } from 'react-toastify';

function CategoryInventory() {
  const dispatch = useDispatch();
  const { categories, selectedCategory, loading } = useSelector((state) => state.categories);
  const [searchTerm, setSearchTerm] = useState(''); // State to store the search term
  const [filteredProducts, setFilteredProducts] = useState([]); // State to store filtered products

  // Fetch categories when the component mounts
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  // Update filtered products when search term or selected category changes
  useEffect(() => {
    if (selectedCategory) {
      const filtered = selectedCategory.products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  }, [searchTerm, selectedCategory]);

  // Handle category change
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    const selected = categories.find(category => category.id === categoryId) || null;
    dispatch(setSelectedCategory(selected));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(updateCategories(categories));
      toast.success('Categories and products updated successfully');
      await dispatch(fetchCategories());
    } catch (error) {
      console.error('Error submitting categories and products:', error);
      toast.error('Failed to update categories and products');
    }
  };

  return (
    <div className="category-table-container">
      <h1>Category Inventory</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit} className="settings-form">
          <div className="category-select">
            <label htmlFor="categorySelect">Select Category</label>
            <select
              id="categorySelect"
              onChange={handleCategoryChange}
              value={selectedCategory ? selectedCategory.id : ''}
            >
              <option value="" disabled>Select a category</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.label}
                </option>
              ))}
            </select>
          </div>

          {/* Search Input for Filtering Products */}
          {selectedCategory && (
            <div>
              <div className="search-container">
                <label htmlFor="searchInput">Search Products</label>
                <input
                  type="text"
                  id="searchInput"
                  className="search-input"
                  placeholder="Search products..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <table className="category-table">
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Product Image</th>
                    <th>Product Name</th>
                    <th>Product Price</th>
                    <th>Product Description</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.map((product) => (
                    <ProductRow 
                      key={product.id}
                      categoryId={selectedCategory.id}
                      product={product}
                    />
                  ))}
                </tbody>
              </table>
              {/* Uncomment the button if you want to use it */}
              {/* <button type="submit" className="submit-button">Submit</button> */}
            </div>
          )}
        </form>
      )}
    </div>
  );
}

export default CategoryInventory;
