import React, { useState } from 'react';
import Box from '../components/box/Box'
import DashboardWrapper, { DashboardWrapperMain, DashboardWrapperRight } from '../components/dashboard-wrapper/DashboardWrapper'

import { Container, Col } from 'reactstrap';
import useGetData from '../hooks/useGetData';
import { toast } from 'react-hot-toast';
import { Pagination } from '@mui/material';
import Loader from '../components/Loader/Loader';
// import {   Form, FormGroup, Label } from 'reactstrap';
import CustomerForm from './Input/CustomerForm';
// import axios from 'axios';
import {StyledButton, StyledTable} from './CustomStyles';
import RoleChange from './Input/RoleChange';
import {deleteData} from '../redux/apiUtils';

const Customers = () => {

    const [userId, setUserId] = useState(null);
    const { data: usersData, loading, refetch } = useGetData('users'); // Include refetch function from useGetData hook

    const deleteUser = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this product?');
        if (confirmed) {
            try {
                await deleteData(`users`,id);
                toast.success('User Deleted');
                refetch(); // Manually refetch products data after successful deletion
            } catch (error) {
                console.error('Error deleting product:', error);
            }
        }
    }


    // Pagination state
    const [page, setPage] = useState(1);
    const usersPerPage = 10; // Number of products per page
    const totalUsers = usersData.length;
    const totalPages = Math.ceil(totalUsers / usersPerPage);

    // Pagination event handler
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    // Calculate start and end index of products to display for the current page
    const startIndex = (page - 1) * usersPerPage;
    const endIndex = Math.min(startIndex + usersPerPage, totalUsers);


    const handleSuccess = (data) => {
    //   console.log('Form submitted successfully:', data);
       refetch();
    };
  
    const toggleMode = (userId) => {
    
        setUserId(userId); // Example userId for update
      
    };
    return (
        <DashboardWrapper>
        
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-8 col-md-12">
                        <div className="row">
                            <div className="col-12 col-md-12 col-sm-12 mb">
                                <div className="title mb"><Box >List of Users</Box></div>
                            </div>

                        </div>
                    </div>
                </div>
                <Box>
                    <div className="mb">
                        <section>
                            <Container>
                                  
                                        <StyledTable className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Mobile</th>
                                                    <th>Username</th>
                                                    <th>Email</th>
                                                    <th>Role</th>
                                                    <th colSpan={2}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? (
                                                    <tr>
                                                        <td colSpan="4">
                                                            <Loader />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    usersData?.slice(startIndex, endIndex).map(user => (
                                                        <tr key={user.id}>
                                                            <td>{user.mobile}</td>
                                                            <td>{user.firstname}</td>
                                                            <td>{user.email}</td>
                                                            <RoleChange user={user}/>
                                                            <td>
                                                                <StyledButton onClick={() => deleteUser(user?.id)} className="btn btn-danger">Delete</StyledButton>
                                                               
                                                            </td>
                                                            <td>
                                                            <StyledButton onClick={() => toggleMode(user?.id)} className="btn btn-primary">Edit</StyledButton>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </StyledTable>
                                 
                                    <Col lg='12' className='text-center'>
                                        <Pagination
                                            count={totalPages}
                                            page={page}
                                            onChange={handlePageChange}
                                            color="primary"
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                </Col>
                            </Container>
                        </section>
                    </div>
                </Box>
            </DashboardWrapperMain>
            <DashboardWrapperRight>
         
            {loading ? (
          <Loader/>
        ) : (
            <div>
           
            <CustomerForm userId={userId} onSuccess={handleSuccess} />
          </div>
        )}
       
            </DashboardWrapperRight>
        </DashboardWrapper>
    )
}

export default Customers

