import React, { useState, useEffect } from 'react';
import useGetData from '../../../hooks/useGetData'; // Adjust the path as needed
import { toast } from 'react-toastify';

function CountersInput() {
  const { data: counters, loading, refetch, postData } = useGetData('home/counters'); // Adjust route as needed
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (counters) {
      setItems(counters);
    }
  }, [counters]);

  const handleChange = (id, field, value) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postData(items);
      // alert('Counters items updated successfully');
      toast.success('Counters items updated successfully');
      refetch();
    } catch (error) {
      console.error('Error submitting Counters items:', error);
      // alert('Failed to update Counters items');
     toast.error('Failed to update Counters items');
    }
  };

  return (
    <div className="vegetables-table-container">
      <h1>Dynamic Counters List</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit} className="vegetables-form">
          <table className="vegetables-table">
            <thead>
              <tr>
                <th>Icon</th>
                <th>Title</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
               

                return (
                  <tr key={index}>
                    <td>
                    <input
                      type="text"
                      className="form-control"
                      value={item.icon}
                      onChange={(e) => handleChange(item.id, 'icon', e.target.value)}
                      disabled
                    />
                     
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={item.title}
                        onChange={(e) => handleChange(item.id, 'title', e.target.value)}
                      
                      />
                    </td>
                  
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        value={item.value}
                        onChange={(e) => handleChange(item.id, 'value',parseFloat( e.target.value))}
                        
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
}

export default CountersInput;
