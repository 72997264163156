import React from 'react';
import { Bar } from 'react-chartjs-2';
import Box from '../components/box/Box';
import DashboardWrapper, { DashboardWrapperMain, DashboardWrapperRight } from '../components/dashboard-wrapper/DashboardWrapper';
import SummaryBox, { SummaryBoxSpecial } from '../components/summary-box/SummaryBox';
import { colors } from '../constants';
import useGetData from '../hooks/useGetData';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import OverallList from '../components/overall-list/OverallList';
import RevenueList from '../components/revenue-list/RevenueList';
import images from "../constants/images";
import Loader from '../components/Loader/Loader';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
)


const Dashboard = () => {
   

    const { data: totals, loading, error } = useGetData('users/totals'); // Destructure data, loading, error from useGetData

    if (loading) {
        return <Loader/>;
    }

    if (error) {
        return <div>Error: {error.message}</div>; // Render error message
    }

    console.log(totals);

    const updatedSummary = [
        {
            title: 'Sales',
            subtitle: 'Total sales',
            value: `$${totals?.total_sales || 0}`,
            percent: 70
        },
        {
            title: 'Orders',
            subtitle: 'Total orders',
            value: `${totals?.total_orders || 0}`,
            percent: 49
        },
        {
            title: 'Products',
            subtitle: 'Total products',
            value: `$${totals?.total_products || 0}`,
            percent: 38
        },
        {
            title: 'Users',
            subtitle: 'Total users',
            value: `${totals?.total_users || 0}`,
            percent: 55
        }
    ];

    const overallsummary=[
        {
            value: `${totals?.total_orders || 0}`,
            title: 'Orders'
        },
        {
            value: `$${totals?.total_users || 0}`,
            title: 'Customers'
        },
        {
            value: `$${totals?.total_products || 0}`,
            title: 'Products'
        },
        {
            value: `$${totals?.total_sales || 0}`,
            title: 'Revenue'
        }
    ];

    const data = {
        user: {
            name: 'moni',
            img: images.avt
        },
        summary: updatedSummary,
        revenueSummary: {
            title: 'Revenue',
            value: `$${totals?.total_sales || 0}`,
            chartData: {
                labels: ['May', 'Jun', 'July', 'Aug', 'May', 'Jun', 'July', 'Aug'],
                data: [300, 300, 280, 380, 200, 300, 280, 350]
            }
        },
        overall: overallsummary,
        revenueByChannel: [
            {
                title: 'Direct',
                value: 70
            },
            {
                title: 'External search',
                value: 40
            },
            {
                title: 'Referral',
                value: 60
            },
            {
                title: 'Social',
                value: 30
            }
        ],
        revenueByMonths: {
            labels: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
            data: [250, 200, 300, 280, 100, 220, 310, 190, 200, 120, 250, 350]
        }
    };

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-8 col-md-12">
                        <div className="row">
                            {
                                data.summary.map((item, index) => (
                                    <div key={`summary-${index}`} className="col-6 col-md-6 col-sm-12 mb">
                                        <SummaryBox item={item} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-4 hide-md">
                        <SummaryBoxSpecial item={data.revenueSummary} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Box>
                            <RevenueByMonthsChart revenueByMonths={data.revenueByMonths} />
                        </Box>
                    </div>
                </div>
            </DashboardWrapperMain>
            <DashboardWrapperRight>
                <div className="title mb">Overall</div>
                <div className="mb">
                    <OverallList />
                </div>
                <div className="title mb">Top Products</div>
                <div className="mb">
                    <RevenueList />
                </div>
            </DashboardWrapperRight>
        </DashboardWrapper>
    );
};

const RevenueByMonthsChart = ({ revenueByMonths }) => {
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                grid: {
                    display: false,
                    drawBorder: false
                }
            }],
            yAxes: [{
                grid: {
                    display: false,
                    drawBorder: false
                }
            }]
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            }
        },
        elements: {
            bar: {
                backgroundColor: colors.orange,
                borderRadius: 20,
                borderSkipped: 'bottom'
            }
        }
    };

    const chartData = {
        labels: revenueByMonths.labels,
        datasets: [
            {
                label: 'Revenue',
                data: revenueByMonths.data
            }
        ]
    };

    return (
        <>
            <div className="title mb">
                Revenue by months
            </div>
            <div>
                <Bar options={chartOptions} data={chartData} height={`300px`} />
            </div>
        </>
    );
};

export default Dashboard;
