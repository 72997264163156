import React, { useState } from 'react';
import './tabs.scss';

function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(Object.keys(tabs)[0]);

  return (
    <div className="tabs-container">
      <ul className="tabs">
        {Object.keys(tabs).map((key) => (
          <li
            key={key}
            className={`tab ${key === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(key)}
          >
            {key}
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {tabs[activeTab]}
      </div>
    </div>
  );
}

export default Tabs;
