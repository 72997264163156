// src/redux/slices/categorySlice.js
import { createSlice } from '@reduxjs/toolkit';

const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    selectedCategory: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchCategoriesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCategoriesSuccess(state, action) {
      state.loading = false;
      state.categories = action.payload;
    },
    fetchCategoriesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateProductSuccess(state, action) {
      const { categoryId, productId, field, value } = action.payload;
      const category = state.categories.find(category => category.id === categoryId);
      if (category) {
        const product = category.products.find(product => product.id === productId);
        if (product) {
          product[field] = value;
        }
      }
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
  },
});

export const {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  updateProductSuccess,
  setSelectedCategory,
} = categorySlice.actions;

export default categorySlice.reducer;
