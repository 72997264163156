import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import ServiceItem from './Tabs/ServiceItem';
import VegetableList from './components/VegetableList';
import ProductCard from './Tabs/ProductCard';
import useHomeData from '../hooks/useHomeData';
import Testimonial from './components/Testimonial';
import DynamicCounters from './Tabs/DynamicCounters';
import CategoryGroup from './Tabs/CategoryGroup';



const ProductList = ({ products }) => {
    return (
        <div className="row g-4">
            {products.map(product => (
                <ProductCard key={product.id} product={product} />
            ))}
        </div>
    );
};



const ServiceList = ({ services }) => (
    <div className="container-fluid service py-5">
        <div className="container py-5">
            <div className="row g-4 justify-content-center">
                {services.map((service, index) => (
                    <ServiceItem
                        key={index}
                        image={service.image}
                        link={service.link}
                        bgClass={service.bgClass}
                        borderClass={service.borderClass}
                        contentBgClass={service.contentBgClass}
                        title={service.title}
                        subtitle={service.subtitle}
                        titleClass={service.titleClass}
                    />
                ))}
            </div>
        </div>
    </div>
);


function HomeSite() {

   
    const { featureItems, vegetables, products, services } = useHomeData();



    return (
        <>


            <Navbar />

            <div className="container-fluid py-5 mb-5 hero-header">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-md-12 col-lg-7">
                            <h4 className="mb-3 text-secondary">100% Organic Foods</h4>
                            <h1 className="mb-5 display-3 text-primary">Organic Veggies & Fruits Foods</h1>
                            <div className="position-relative mx-auto">
                                <input className="form-control border-2 border-secondary w-75 py-3 px-4 rounded-pill" type="number" placeholder="Search" />
                                <button
                                    type="submit"
                                    className="btn btn-primary border-2 border-secondary py-3 px-4 position-absolute rounded-pill text-white h-100"
                                    style={{ top: 0, right: '25%' }}
                                >
                                    Submit Now
                                </button>

                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5">
                            <div id="carouselId" className="carousel slide position-relative" data-bs-ride="carousel">
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active rounded">
                                        <img src="img/hero-img-1.png" className="img-fluid w-100 h-100 bg-secondary rounded" alt="First slide" />
                                        <a href="/" className="btn px-4 py-2 text-white rounded">Fruites</a>
                                    </div>
                                    <div className="carousel-item rounded">
                                        <img src="img/hero-img-2.jpg" className="img-fluid w-100 h-100 rounded" alt="Second slide" />
                                        <a href="/" className="btn px-4 py-2 text-white rounded">Vegetables</a>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid features py-5">
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                        {featureItems.map(item => (
                            <div className="col" key={item.id}>
                                <div className="features-item rounded bg-light p-4 text-center">
                                    <div className="features-icon bg-secondary rounded-circle mb-4 mx-auto p-3" style={{ width: '100px', height: '80px' }}>
                                        <i className={item.iconClass + " text-white"} style={{ fontSize: '3rem' }}></i>
                                    </div>
                                    <div className="features-content">
                                        <h5 className="mb-3">{item.title}</h5>
                                        <p className="mb-0">{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            
            <CategoryGroup/>

            <ServiceList services={services} />


            <VegetableList vegetables={vegetables} />



            <div className="container-fluid banner bg-secondary my-5">
                <div className="container py-5">
                    <div className="row g-4 align-items-center">
                        <div className="col-lg-6">
                            <div className="py-4">
                                <h1 className="display-3 text-white">Fresh Exotic Fruits</h1>
                                <p className="fw-normal display-3 text-dark mb-4">in Our Store</p>
                                <p className="mb-4 text-dark">The generated Lorem Ipsum is therefore always free from repetition injected humour, or non-characteristic words etc.</p>
                                <a href="/shop" className="banner-btn btn border-2 border-white rounded-pill text-dark py-3 px-5">BUY</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="position-relative">
                                <img src="img/baner-1.png" className="img-fluid w-100 rounded" alt="" />
                                <div
                                    className="d-flex align-items-center justify-content-center bg-white rounded-circle position-absolute"
                                    style={{ width: '140px', height: '140px', top: 0, left: 0 }}
                                >
                                    <h1 style={{ fontSize: '100px' }}>1</h1>

                                    <div className="d-flex flex-column">
                                        <span className="h2 mb-0">50$</span>
                                        <span className="h4 text-muted mb-0">kg</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div
                        className="text-center mx-auto mb-5"
                        style={{ maxWidth: '700px' }}
                    >
                        <h1 className="display-4">Bestseller Products</h1>
                        <p>Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                    </div>

                    <ProductList products={products} />
                </div>
            </div>

            <DynamicCounters />
            <Testimonial />
            <Footer />
        </>

    )
}

export default HomeSite