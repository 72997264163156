import React, { useState } from 'react';
import DashboardWrapper,{  DashboardWrapperMain, DashboardWrapperRight } from '../components/dashboard-wrapper/DashboardWrapper';
import { postData, fetchData } from '../redux/apiUtils';
import './ExportJson.scss';
import {toast} from 'react-toastify'

const ExportJson = () => {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleImport = async () => {
        if (!file) {
            //alert('Please select a file to import');
            toast.error('Please select a file to import')
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            await postData('products/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            //alert('Data imported successfully');
            toast.success('Data imported successfully')
        } catch (error) {
            console.error('Error importing data:', error);
            toast.error('Failed to import data')
            //alert('Failed to import data');
        }
    };

    const handleExport = async () => {
        try {
            const response = await fetchData('products/export');
            const downloadUrl = response.download_url;

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'all_data.json');
            document.body.appendChild(link);
            link.click();

            // Remove the link from the DOM
            link.remove();
        } catch (error) {
            console.error('Error exporting data:', error);
            //alert('Failed to export data');
            toast.error('Failed to export data')
        }
    };

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <h1>Data Import/Export</h1>
                <div className="file-import">
                    <input type="file" onChange={handleFileChange} disabled />
                    <button onClick={handleImport} disabled>Import Data</button>
                </div>
                 <div className="file-export">
                <button onClick={handleExport} disabled>Export Data</button>
                </div>
                <p>Note: This is used only by the administrator</p>
            </DashboardWrapperMain>
            <DashboardWrapperRight>
               
            </DashboardWrapperRight>
        </DashboardWrapper>
    );
};

export default ExportJson;








