import React, { useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Breadcrumb from '../Breadcrumb';
import { postData } from '../../redux/apiUtils'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Signup() {
  const title = 'Signup';
  const navigate = useNavigate();
  
  // State for form inputs
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    mobile: '',
    email: '',
    is_active: true
  });

  // State for error handling
  const [error, setError] = useState('');

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postData('auth/register', formData);
      toast.success('Registration successful! Please log in.', {
        onClose: () => navigate('/login')
      });
    } catch (error) {
      console.error('Error during registration:', error);
      setError(error.message);
    }
  };

  return (
    <>
      <Navbar />
      <Breadcrumb title={title} />
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1 className="mb-4">Signup</h1>
          <form onSubmit={handleSubmit}>
            <div className="row g-5">
              <div className="col-md-12 col-lg-6 col-xl-7">
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <div className="form-item w-100">
                      <label className="form-label my-3">First Name<sup>*</sup></label>
                      <input type="text" name="firstname" className="form-control" value={formData.firstname} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="form-item w-100">
                      <label className="form-label my-3">Last Name<sup>*</sup></label>
                      <input type="text" name="lastname" className="form-control" value={formData.lastname} onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Mobile<sup>*</sup></label>
                  <input type="tel" name="mobile" className="form-control" value={formData.mobile} onChange={handleChange} />
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Email Address<sup>*</sup></label>
                  <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} />
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Password<sup>*</sup></label>
                  <input type="password" name="password" className="form-control" value={formData.password} onChange={handleChange} />
                </div>
                <div className="form-check my-3">
                  <input type="checkbox" className="form-check-input" id="Account-1" name="is_active" checked={formData.is_active} onChange={handleChange} enabled/>
                  <label className="form-check-label" htmlFor="Account-1">Create an account?</label>
                </div>
                <hr />
                {error && <p className="text-danger">{error}</p>}
                <button type="submit" className="btn btn-primary">Register</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
