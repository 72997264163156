import React, { useRef } from 'react'; // Import useRef
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import './styles.scss'; // Import the SCSS file
import VegetableItem from './VegetableItem'; // Adjust the import path as needed

const VegetableList = ({ vegetables }) => {
  // Create a reference to the OwlCarousel instance
  const owlRef = useRef(null);

//   // Function to go to the previous item
//   const goToPrev = () => {
//     if (owlRef.current) {
//       // Ensure the method exists on the ref
//       if (typeof owlRef.current.prev === 'function') {
//         owlRef.current.prev();
//       }
//     }
//   };

//   // Function to go to the next item
//   const goToNext = () => {
//     if (owlRef.current) {
//       // Ensure the method exists on the ref
//       if (typeof owlRef.current.next === 'function') {
//         owlRef.current.next();
//       }
//     }
//   };

  return (
    <div className="container-fluid vegetable py-5">
      <div className="container py-5">
        <h1 className="mb-0">Fresh Organic Vegetables</h1>
        <OwlCarousel
          className="owl-carousel vegetable-carousel justify-content-center"
          loop={true} // Ensure looping is enabled
          margin={10}
          nav={false} // Disable default nav
          items={3} // Number of items to show
          autoplay={true} // Enable autoplay
          autoplayTimeout={2000} // Time between slides in milliseconds
          autoplayHoverPause={true} // Pause on hover
          ref={owlRef} // Attach the ref
          responsive={{
            0: {
              items: 1, // Show 1 item on small screens
            },
            600: {
              items: 2, // Show 2 items on medium screens
            },
            1000: {
              items: 3, // Show 3 items on large screens
            }
          }}
        >
          {vegetables.map((vegetable, index) => (
            <VegetableItem
              key={index}
              image={vegetable.image}
              link={vegetable.link}
              bgClass={vegetable.bgClass}
              title={vegetable.title}
              description={vegetable.description}
              price={vegetable.price}
            />
          ))}
        </OwlCarousel>
        {/* Custom navigation controls */}
        {/* <div className="custom-nav-left" onClick={goToPrev}>
          <i className="bi bi-arrow-left"></i>
        </div>
        <div className="custom-nav-right" onClick={goToNext}>
          <i className="bi bi-arrow-right"></i>
        </div> */}
      </div>
    </div>
  );
};

export default VegetableList;
