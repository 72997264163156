import React from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {useCart} from '../../context/CartContext';

const ShoppingCartLink = () => {
  const {user} = useAuth();
  const {  getTotalQuantity } = useCart();
  //console.log(getTotalQuantity());

  const logout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    window.location.href = '/';
  };
  

  return (
    <>
     <Link to="/cart" className="position-relative me-4 my-auto">
            <i className="fa fa-shopping-bag fa-2x"></i>
            <span
              className="position-absolute bg-secondary rounded-circle d-flex align-items-center justify-content-center text-dark px-1"
              style={{ top: '-5px', left: '15px', height: '20px', minWidth: '20px' }}
            >
              {getTotalQuantity()}
            </span>
          </Link>
      {user ? (
        <>
         
          <button onClick={logout} className="btn btn-link my-auto">
            <i className="fas fa-sign-out-alt fa-2x"></i>
          </button>
        </>
      ) : (
        <Link to="/login" className="my-auto">
          <i className="fas fa-user fa-2x"></i>
        </Link>
      )}
    </>
  );
};

export default ShoppingCartLink;
