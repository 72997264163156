import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateProduct } from '../../../redux/thunks/categoryThunks';
import {  putData } from '../../../redux/apiUtils';

function ProductRow({ categoryId, product }) {
  const dispatch = useDispatch();

  // Initialize local state with the provided product or default to empty values
  const [localProduct, setLocalProduct] = useState(product || {
    id: '',
    name: '',
    price: 0,
    image: '',
    description: '',
    category_id: categoryId
  });

  // Function to handle changes and update local state
  const handleLocalChange = useCallback((field, value) => {
    setLocalProduct(prevProduct => ({
      ...prevProduct,
      [field]: value
    }));
  }, []);

  const handleImageUpload = async (id, file) => {
    if (!file) return;
  
    // Create a FormData object to hold the image data
    const formData = new FormData();
    formData.append('image', file);
  
    try {
      // Send the image to the server using the putData function
      const response = await putData(`products/image/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Handle the successful response (you can update UI here if needed)
      console.log('Image uploaded successfully:', response);
    } catch (error) {
      console.error('Failed to upload image:', error);
    }
  //  refetch();
  };


  // Function to dispatch updates to Redux store
  const handleUpdateProduct = useCallback((field, value) => {
    handleLocalChange(field, value);
    if (product) {
      dispatch(updateProduct({ categoryId, productId: product.id, field, value }));
    } else {
      // Handle the case where you might need to add a new product
      console.log('New product creation logic goes here');
      // You would dispatch an action to create a new product here
    }
  }, [dispatch, categoryId, product, handleLocalChange]);

  const imageUrl = localProduct.image
  ? (localProduct.image.startsWith('http://') || localProduct.image.startsWith('https://') 
    ? localProduct.image 
    : process.env.PUBLIC_URL + '/' + localProduct.image)
  : '';

  return (
    <tr key={localProduct.id || 'new'}>
      <td>{localProduct.id || 'New'}</td>
      <td>
        <input
          type="text"
          className="form-control"
          placeholder="Image URL"
          value={localProduct.image || ''}
          onChange={(e) => handleUpdateProduct('image', e.target.value)}
          disabled
        />
        {localProduct.image && <img src={imageUrl} alt={localProduct.name} className="item-image" />}
        <input
            type="file"
            className="form-control"
            onChange={(e) => handleImageUpload(localProduct.id, e.target.files[0])}
            />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          placeholder="Product Name"
          value={localProduct.name || ''}
          onChange={(e) => handleUpdateProduct('name', e.target.value)}
        />
      </td>
      <td>
        <input
          type="number"
          className="form-control"
          placeholder="Price"
          value={localProduct.price || ''}
          onChange={(e) => handleUpdateProduct('price', parseFloat(e.target.value) || 0)}
        />
      </td>
      <td>
        <input
          type="text"
          className="form-control"
          placeholder="Description"
          value={localProduct.description || ''}
          onChange={(e) => handleUpdateProduct('description', e.target.value)}
        />
      </td>
    </tr>
  );
}

export default ProductRow;
