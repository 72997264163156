import React, { useState, useEffect } from 'react';
import useGetData from '../../../hooks/useGetData'; // Adjust the path as needed
import { toast } from 'react-toastify';
import {  putData } from '../../../redux/apiUtils';

function VegetableInput() {
  const { data: vegetableItems, loading, refetch, postData } = useGetData('home/vegetable-items'); // Adjust route as needed
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (vegetableItems) {
      setItems(vegetableItems);
    }
  }, [vegetableItems]);

  const handleChange = (id, field, value) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };
  const handleImageUpload = async (id, file) => {
    if (!file) return;
  
    // Create a FormData object to hold the image data
    const formData = new FormData();
    formData.append('image', file);
  
    try {
      // Send the image to the server using the putData function
      const response = await putData(`home/vegetable/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Handle the successful response (you can update UI here if needed)
      console.log('Image uploaded successfully:', response);
    } catch (error) {
      console.error('Failed to upload image:', error);
    }
    refetch();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postData(items);
      // alert('Vegetable items updated successfully');
      toast.success('Vegetable items updated successfully');
      refetch();
    } catch (error) {
      console.error('Error submitting vegetable items:', error);
      // alert('Failed to update vegetable items');
      toast.error('Failed to update vegetable items');
    }
  };

  return (
    <div className="vegetables-table-container">
      <h1>Vegetables List</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit} className="vegetables-form">
          <table className="vegetables-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                const imagePath = item.image;
                const imageUrl = imagePath
                ? (imagePath.startsWith('http://') || imagePath.startsWith('https://') 
                  ? imagePath 
                  : process.env.PUBLIC_URL + '/' + imagePath)
                : '';

                return (
                  <tr key={index}>
                    <td>
                    <input
                        type="text"
                        className="form-control"
                        value={imageUrl}
                        onChange={(e) => handleChange(item.id, 'image', e.target.value)}
                      disabled
                      />
                      <img src={imageUrl} alt={item.title} className="item-image" />
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleImageUpload(item.id, e.target.files[0])}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={item.title}
                        onChange={(e) => handleChange(item.id, 'title', e.target.value)}
                      
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={item.description}
                        onChange={(e) => handleChange(item.id, 'description', e.target.value)}
                        
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        value={item.price}
                        onChange={(e) => handleChange(item.id, 'price',parseFloat( e.target.value))}
                        
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
}

export default VegetableInput;
