import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useGetData from '../hooks/useGetData'; // Adjust the import based on your project structure
import Loader from '../components/Loader/Loader';
import ShoppingCartLink from './Auth/ShoppingCartLink';

const MenuBar = () => {
  const { data: menuItems, loading, refetch } = useGetData('menu-items');
  // const [menuItems, setMenuItems] = useState([]); // No need for separate state management

  // Fetch data using the hook, if refetch is necessary
  useEffect(() => {
    refetch();
  }, [refetch]);

  const renderMenuItems = (items, parentId = "0") => {
    const filteredItems = items.filter(item => item.parent_id === parentId);
  
    if (filteredItems.length === 0) {
      return null;
    }
  
    return (
      <ul className={parentId === "0" ? "navbar-nav mx-auto" : "dropdown-menu m-0 bg-secondary rounded-0"}>
        {filteredItems.map(item => {
          const children = items.filter(subItem => subItem.parent_id === item.id);
          return (
            <li key={item.id} className={`nav-item ${children.length > 0 ? "dropdown" : ""}`}>
              {children.length > 0 ? (
                <>
                  <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{item.name}</Link>
                  {renderMenuItems(items, item.id)}
                </>
              ) : (
                <Link to={item.link} className="nav-link">{item.name}</Link>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  if (loading) {
    return <Loader/>; // Show a loading indicator while data is being fetched
  }

  return (
    <div className="container px-0">
      <nav className="navbar navbar-light bg-white navbar-expand-xl">
        <Link to="/" className="navbar-brand">
          <h1 className="text-primary display-6">Vfast</h1>
        </Link>
        <button
          className="navbar-toggler py-2 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars text-primary"></span>
        </button>
        <div className="collapse navbar-collapse bg-white" id="navbarCollapse">
          {renderMenuItems(menuItems)}
          <div className="d-flex m-3 me-0">
            <button
              className="btn-search btn border border-secondary btn-md-square rounded-circle bg-white me-4"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i className="fas fa-search text-primary"></i>
            </button>
           
            <ShoppingCartLink/>
          
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MenuBar;
