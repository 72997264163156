import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

// const BASE_URL = 'http://localhost:8080/api'; 
// const BASE_URL = 'https://vfastservices.online/api'; 
const BASE_URL = process.env.REACT_APP_BASE_BACKEND_URL;

const useGetData = (route) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch data from the API
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            // Retrieve JWT token from local storage or wherever it's stored
            const token = localStorage.getItem('token');

            //console.log('token', token);

            const response = await axios.get(`${BASE_URL}/${route}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include JWT token in the request headers
                },
            });

            if (response.status === 200) {
                setData(response.data);
                setLoading(false);
            } else {
                console.error('Error fetching data:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    }, [route]);

    // Post data to the API
    const postData = useCallback(async (payload) => {
        setLoading(true);
        try {
            // Retrieve JWT token from local storage or wherever it's stored
             const token = localStorage.getItem('token');

            const response = await axios.post(`${BASE_URL}/${route}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include JWT token in the request headers
                },
            });

            if (response.status === 200 || response.status === 201) {
                // Optionally, refetch the data or update local state
                await fetchData();
                return response.data; // Return the response data from POST request
            } else {
                console.error('Error posting data:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while posting data:', error);
        }
        setLoading(false);
    }, [route, fetchData]);

    // Put data to the API
    const putData = useCallback(async (payload) => {
        setLoading(true);
        try {
            // Retrieve JWT token from local storage or wherever it's stored
            const token = localStorage.getItem('token');

            const response = await axios.put(`${BASE_URL}/${route}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include JWT token in the request headers
                },
            });

            if (response.status === 200 || response.status === 204) {
                // Optionally, refetch the data or update local state
                await fetchData();
                return response.data; // Return the response data from PUT request
            } else {
                console.error('Error putting data:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while putting data:', error);
        }
        setLoading(false);
    }, [route, fetchData]);

    // Delete data from the API
    const deleteData = useCallback(async (id) => {
        setLoading(true);
        try {
            // Retrieve JWT token from local storage or wherever it's stored
            // const token = localStorage.getItem('jwtToken');

            const response = await axios.delete(`${BASE_URL}/${route}/${id}`, {
                // headers: {
                //     Authorization: `Bearer ${token}`, // Include JWT token in the request headers
                // },
            });

            if (response.status === 200 || response.status === 204) {
                // Optionally, refetch the data or update local state
                await fetchData();
                return response.data; // Return the response data from DELETE request
            } else {
                console.error('Error deleting data:', response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while deleting data:', error);
        }
        setLoading(false);
    }, [route, fetchData]);

    // Refetch data
    const refetch = useCallback(() => {
        fetchData(); // Call fetchData again to refetch the data
    }, [fetchData]);

    // Initial fetch on mount
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, loading, refetch, postData, putData, deleteData };
};

export default useGetData;
