import React, { useState } from 'react';

function AddCategoryForm({ onSubmit }) {
  const [newCategory, setNewCategory] = useState({ label: '', products: '' });

  const handleChange = (field, value) => {
    setNewCategory(prevCategory => ({
      ...prevCategory,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(newCategory);
      setNewCategory({ label: '', products: '' }); // Clear input fields after submission
    }
  };

  return (
    <form onSubmit={handleSubmit} className="add-category-form">
      <table className="category-table">
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                className="form-control"
                placeholder="New Category Label"
                value={newCategory.label}
                onChange={(e) => handleChange('label', e.target.value)}
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                placeholder="New Products (comma separated)"
                value={newCategory.products}
                onChange={(e) => handleChange('products', e.target.value)}
                disabled // You can enable this if needed
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button type="submit" className="submit-button">Add Category</button>
    </form>
  );
}

export default AddCategoryForm;
