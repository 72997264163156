import { useEffect, useState } from 'react';
import useGetData from './useGetData';

// Define URLs for each type of data
const FEATURE_ITEMS_URL = 'home/feature-items';
const FRUIT_ITEMS_URL = 'home/fruit-items';
const VEGETABLE_ITEMS_URL = 'home/vegetable-items';
const PRODUCT_ITEMS_URL = 'home/product-items';
const FRUIT1_ITEMS_URL = 'home/fruit1-items';
const SERVICES_ITEMS_URL = 'home/service-items';

const useHomeData = () => {
  const [featureItems, setFeatureItems] = useState([]);
  const [fruits, setFruits] = useState([]);
  const [vegetables, setVegetables] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [fruitData, setFruitData] = useState([]);

  const featureItemsData = useGetData(FEATURE_ITEMS_URL); 
  const fruitsData = useGetData(FRUIT1_ITEMS_URL); 
  const vegetablesData = useGetData(VEGETABLE_ITEMS_URL); 
  const productsData = useGetData(PRODUCT_ITEMS_URL); 
  const servicesData = useGetData(SERVICES_ITEMS_URL); 
  const fruitDataData = useGetData(FRUIT_ITEMS_URL); 

  useEffect(() => {
    if (featureItemsData) setFeatureItems(featureItemsData.data);
  }, [featureItemsData]);

  useEffect(() => {
    if (fruitsData) setFruits(fruitsData.data);
  }, [fruitsData]);

  useEffect(() => {
    if (vegetablesData) setVegetables(vegetablesData.data);
  }, [vegetablesData]);

  useEffect(() => {
    if (productsData) setProducts(productsData.data);
  }, [productsData]);

  useEffect(() => {
    if (servicesData) setServices(servicesData.data);
  }, [servicesData]);

  useEffect(() => {
    if (fruitDataData) setFruitData(fruitDataData.data);
  }, [fruitDataData]);

  return { featureItems, fruits, vegetables, products, services, fruitData };
};

export default useHomeData;
