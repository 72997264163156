import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Breadcrumb from './Breadcrumb';

function Contact() {


  const bangaloreCoords = {
    lat: 12.9716, // Latitude of Bangalore
    lng: 77.5946, // Longitude of Bangalore
  };

  // Construct the iframe URL with dynamic location and zoom level
  const iframeSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d${150000}!2d${bangaloreCoords.lng}!3d${bangaloreCoords.lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13f094c79c01%3A0x57c97e4b6d6f15fe!2sBangalore%2C%20India!5e0!3m2!1sen!2sin!4v1655560800000!5m2!1sen!2sin`;
  const title = 'Contact';

  return (
    <>
      <Navbar />

      <Breadcrumb title={title}/>

      <div className="container-fluid contact py-5">
        <div className="container py-5">
          <div className="p-5 bg-light rounded">
            <div className="row g-4">
              <div className="col-12">
                <div className="text-center mx-auto" style={{maxWidth: '700px'}}>
                  <h1 className="text-primary">Get in touch</h1>
                  <p className="mb-4">The contact form is currently inactive.</p>
                </div>
              </div>
              <div className="col-lg-12">
               <div className="h-100 rounded">
                <iframe
                  className="rounded w-100"
                  style={{ height: '400px' }}
                  src={iframeSrc}
                  loading="lazy"
                  title='contact-map'
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              </div>
              <div className="col-lg-7">
                <form action="" className="">
                  <input type="text" className="w-100 form-control border-0 py-3 mb-4" placeholder="Your Name"/>
                    <input type="email" className="w-100 form-control border-0 py-3 mb-4" placeholder="Enter Your Email"/>
                      <textarea className="w-100 form-control border-0 mb-4" rows="5" cols="10" placeholder="Your Message"></textarea>
                      <button className="w-100 btn form-control border-secondary py-3 bg-white text-primary " type="submit">Submit</button>
                    </form>
                  </div>
                  <div className="col-lg-5">
                    <div className="d-flex p-4 rounded mb-4 bg-white">
                      <i className="fas fa-map-marker-alt fa-2x text-primary me-4"></i>
                      <div>
                        <h4>Address</h4>
                        <p className="mb-2">Bangalore, Rajajinagar</p>
                      </div>
                    </div>
                    <div className="d-flex p-4 rounded mb-4 bg-white">
                      <i className="fas fa-envelope fa-2x text-primary me-4"></i>
                      <div>
                        <h4>Mail Us</h4>
                        <p className="mb-2">mohan18.welcome@gmail.com</p>
                      </div>
                    </div>
                    <div className="d-flex p-4 rounded bg-white">
                      <i className="fa fa-phone-alt fa-2x text-primary me-4"></i>
                      <div>
                        <h4>Telephone</h4>
                        <p className="mb-2">9535020534</p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>




        <Footer />

      </>
      )
}

      export default Contact