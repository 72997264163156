import { useState, useEffect } from 'react';
import { fetchUser } from '../redux/apiUtils';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    // Define an async function within useEffect
    const fetchUserData = async () => {
      try {
        const userResponse = await fetchUser('users/details');
        // Update state only if the component is still mounted
        if (isMounted) {
          setUser(userResponse); // Ensure you're accessing data properly
          setLoading(false); // Set loading to false after data is fetched
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoading(false); // Ensure loading is false on error
      }
    };

    fetchUserData(); // Call the async function

    // Cleanup function to set isMounted to false
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array ensures this effect runs once on mount

  return { user, loading }; // Return loading along with user
};

export default useAuth;
