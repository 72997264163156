import React, { useState } from 'react';
import Modal from 'react-modal';
import Navbar from '../Navbar';
import Breadcrumb from '../Breadcrumb';
import Footer from '../Footer';
import styles from './ClientOrder.module.scss';
import useGetData from '../../hooks/useGetData';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ClientOrder = () => {
  const { data: orders, loading, error } = useGetData('orders/user');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();


  const title = 'Order Status';

  const openModal = (order) => {
    setSelectedOrder(order);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedOrder(null);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredOrders.map(order => ({
      'Order ID': order.id,
      'Customer': `${order.form_data.first_name} ${order.form_data.last_name}`,
      'Order': order.form_data.cart_items.map(p => p.name).join(', ') || 'N/A',
      'Address': order.form_data.address,
      'Total': order.total.toFixed(2),
      'Status': order.order_status,
      'Payment': order.payment,
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    XLSX.writeFile(wb, 'Orders.xlsx');
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOrders = orders?.filter(order => 
    order.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.form_data.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.form_data.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.order_status.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.payment.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div className={styles.container}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.container}>Error loading orders: {error.message}</div>;
  }

  if (!orders || orders.length === 0) {
    // return <div className={styles.container}>No order data available</div>;
    toast.info('No order data available', {
      onClose: () => navigate('/')
    });
  }


  return (
    <>
      <Navbar />
      <Breadcrumb title={title} />
      {orders && orders.length > 0 ? (
        <div className={styles.container}>
          <h1 className={styles.title}>Order Status</h1>
          <div className={styles.header}>
            <input
              type="text"
              placeholder="Search for order ID, customer, order status or something..."
              className={styles.searchBar}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button className={styles.exportButton} onClick={exportToExcel}>
              Export as .xlsx
            </button>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer</th>
                {/* <th>Order</th> */}
                {/* <th>Delivery Date</th> */}
                <th>Delivery Pricing</th>
                <th>Delivery Status</th>
                <th>Payment</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <tr key={order.id}>
                  <td>
                    <button className={styles.linkButton} onClick={() => openModal(order)}>
                      {order.id}
                    </button>
                  </td>
                  <td>{order.form_data.first_name} {order.form_data.last_name}</td>
                  {/* <td>{order.order}</td> */}
                  {/* <td>{order.delivery_date}</td> */}
                  <td>${order.total}</td>
                  <td>{order.order_status}</td>
                  <td>{order.payment}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {selectedOrder && (
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Order Details"
              className={styles.modal}
              overlayClassName={styles.overlay}
            >
              <div className={styles.modalHeader}>
                <h2>Order Details</h2>
                <button onClick={closeModal} className={styles.closeButton}>×</button>
              </div>
              <div className={styles.modalContent}>
                <div className={styles.orderSection}>
                  <h3>Order Summary</h3>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Status</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{selectedOrder.id}</td>
                        <td>{selectedOrder.order_status}</td>
                        <td>${selectedOrder.total}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={styles.orderSection}>
                  <h3>Billing Details</h3>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Company</th>
                        <th>Address</th>
                        <th>Town/City</th>
                        <th>Country</th>
                        <th>Postcode/ZIP</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Order Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{selectedOrder.form_data.first_name}</td>
                        <td>{selectedOrder.form_data.last_name}</td>
                        <td>{selectedOrder.form_data.company_name}</td>
                        <td>{selectedOrder.form_data.address}</td>
                        <td>{selectedOrder.form_data.town_city}</td>
                        <td>{selectedOrder.form_data.country}</td>
                        <td>{selectedOrder.form_data.postcode_zip}</td>
                        <td>{selectedOrder.form_data.mobile}</td>
                        <td>{selectedOrder.form_data.email_address}</td>
                        <td>{selectedOrder.form_data.order_notes}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={styles.orderSection}>
                  <h3>Cart Items</h3>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedOrder.form_data.cart_items.map((item) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>${item.price}</td>
                          <td>{item.quantity}</td>
                          <td>${item.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Modal>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <h1>No orders found</h1>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ClientOrder;
