import React from 'react';
import useGetData from '../../hooks/useGetData'; // Adjust the path as necessary
import Loader from '../../components/Loader/Loader';

const Counter = ({ icon, title, value }) => (
  <div className="col-md-6 col-lg-6 col-xl-3">
    <div className="counter bg-white rounded p-5">
      <i className={`fa ${icon} text-secondary`}></i>
      <h4>{title}</h4>
      <h1>{value}</h1>
    </div>
  </div>
);

const DynamicCounters = () => {
  const { data: counters, loading, error } = useGetData('home/counters'); // Adjust the path as necessary

  if (loading) return <Loader/>;
  if (error) return <div>Error loading data</div>;
  if (!counters || counters.length === 0) return <div>No data available</div>;

  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="bg-light p-5 rounded">
          <div className="row g-4 justify-content-center">
            {counters.map((counter, index) => (
              <Counter
                key={index}
                icon={counter.icon}
                title={counter.title}
                value={counter.value}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicCounters;
