import './assets/libs/boxicons-2.1.1/css/boxicons.min.css';
import './scss/App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Blank from './pages/Blank';
import Dashboard from './pages/Dashboard';
import MainLayout from './layout/MainLayout';
import Customers from './pages/Customers';
import Products from './pages/Products';
import Orders from './pages/Orders';
import HomeSite from './pages/HomeSite';
import Shop from './pages/Shop';
import ShopDetail from './pages/ShopDetail';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Testimonial from './pages/Testimonial';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import Settings from './pages/Settings';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import ProtectedRoute from './pages/Auth/ProtectedRoute';
import { CartProvider } from './context/CartContext';
import CategoryDetail from './pages/Tabs/CategoryDetail';
// import CategoryGroup from './pages/Tabs/CategoryGroup';
// import ProductDetail from './pages/Input/ProductDetails';
import ClientOrder from './pages/cart/ClientOrder';
import ExportJson from './pages/ExportJson';

function App() {
    return (
        <CartProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomeSite />} />
                    <Route path="shop" element={<Shop />} />
                    <Route path="shop/product/:id" element={<ShopDetail />} />
                    
                    <Route element={<ProtectedRoute allowedRoles={["customer"]} />}>
                        <Route path="cart" element={<Cart />} />
                        <Route path="checkout" element={<Checkout />} />
                        <Route path="orderStatus" element={<ClientOrder />} />
                    </Route>
                    <Route path="login" element={<Login />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="testimonial" element={<Testimonial />} />
                    <Route path="/category/:id" element={<CategoryDetail />} />
                    <Route path="contact" element={<Contact />} />

                    <Route path="*" element={<NotFound />} />
                    <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
                        <Route path="/admin/" element={<MainLayout />}>
                            <Route path="orders" element={<Orders />} />
                            <Route path="products" element={<Products />} />
                            <Route path="customers" element={<Customers />} />
                            <Route path="settings" element={<Settings />} />
                            {/* <Route path="stats" element={<Dashboard />} /> */}
                            <Route path="stats" element={<ExportJson />} />
                            <Route path="dashboard" element={<Dashboard />} />

                        </Route>
                    </Route>
                    {/* <Route element={<ProtectedRoute allowedRoles={['vendor']} />}>
                         <Route path="/vendor" element={<VendorDashboard />} />
                    </Route> */}
                </Routes>
            </BrowserRouter>
        </CartProvider>
    )
}

export default App
