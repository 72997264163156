import React, { useState, useEffect } from 'react';
import useGetData from '../../../hooks/useGetData'; // Adjust the path as needed
import AddCategoryForm from '../AddCategoryForm'; // Adjust the path as needed
import './CategoryInput.scss'; // Adjust the path as needed
import { toast } from 'react-toastify';
import { deleteData } from '../../../redux/apiUtils'; // Assume this is a utility for sending DELETE requests
import { StyledButton } from '../../CustomStyles';

function CategoryInput() {
  const { data: categories, loading, refetch, postData } = useGetData('home/categories'); // Adjust route as needed
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (categories) {
      setItems(categories);
    }
  }, [categories]);

  const handleChange = (id, field, value) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleAddCategory = async (newCategory) => {
    try {
      await postData(newCategory);
      toast.success('Category added successfully');
      refetch();
    } catch (error) {
      console.error('Error adding category:', error);
      toast.error('Failed to add category');
    }
  };

  const handleDeleteCategory = async (id) => {
    if (!window.confirm('Are you sure you want to delete this category?')) return;

    try {
      await deleteData('home/categories',id); // Adjust route as needed for deletion
      toast.success('Category deleted successfully');
      refetch(); // Refetch categories to update the list
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Failed to delete category');
    }
  };

  return (
    <div className="category-table-container">
      <h1>Dynamic Category List</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <AddCategoryForm onSubmit={handleAddCategory} />
          <table className="category-table">
            <thead>
              <tr>
                <th>Label</th>
                <th>Products</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={item.label}
                      onChange={(e) => handleChange(item.id, 'label', e.target.value)}
                    />
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      value={item.products.map(product => product.name).join(', ')}
                      readOnly
                    />
                  </td>
                  <td>
                    <StyledButton
                      className="delete-button"
                      onClick={() => handleDeleteCategory(item.id)}
                    >
                      Delete
                    </StyledButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default CategoryInput;
