import React, { useState, useEffect } from 'react';
import useGetData from '../../../hooks/useGetData'; // Adjust the path as needed
import { toast } from 'react-toastify';

function FeatureInput() {
  // Initialize the hook with the route for fetching feature items
  const { data: featureItems, loading, refetch, postData } = useGetData('home/feature-items'); // Adjust route as needed
  const [items, setItems] = useState([]);

  // Update local state when featureItems data is fetched
  useEffect(() => {
    if (featureItems) {
      setItems(featureItems);
    }
  }, [featureItems]);

  // Handle input changes
  const handleChange = (id, field, value) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postData(items);
      //alert('Feature items updated successfully');
      toast.success('Feature items updated successfully');
      refetch(); // Optionally refetch the data to see the updates
    } catch (error) {
      console.error('Error submitting feature items:', error);
      toast.error('Failed to update feature items');
      //alert('Failed to update feature items');
    }
  };

  return (
    <div className="settings-container">
      <h1>Feature Items</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit} className="settings-form">
          <table className="feature-items-table">
            <thead>
              <tr>
                <th>Item ID</th>
                <th>Icon Class</th>
                <th>Title</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={item.iconClass}
                      onChange={(e) => handleChange(item.id, 'iconClass', e.target.value)}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={item.title}
                      onChange={(e) => handleChange(item.id, 'title', e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={item.description}
                      onChange={(e) => handleChange(item.id, 'description', e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
}

export default FeatureInput;
