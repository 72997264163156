import React from 'react';
import './scss/Settings.scss'; // Import custom CSS file
// import useHomeData from '../hooks/useHomeData';
import TabsGroup from './Input/home/TabsGroup';

function Settings() {
  // const {  vegetables} = useHomeData();

  return (
    <>
      <br />
      <h1>Home Page Settings</h1>
      <hr />
      <br />
    
      <TabsGroup />
     

    </>


  );
}

export default Settings;
