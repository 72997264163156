import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { StyledButton, StyledInput ,StyledSelect} from '../CustomStyles';
import Box from '../../components/box/Box';
import toast from 'react-hot-toast';
import { fetchData,postData,putData } from '../../redux/apiUtils';

const CustomerForm = ({ userId, onSuccess }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [enterFirstname, setEnterFirstName] = useState('');
  const [enterEmail, setEnterEmail] = useState('');
  const [enterPassword, setEnterPassword] = useState('');
  const [enterMobile, setEnterMobile] = useState('');
  const [enterRole, setEnterRole] = useState('');


  useEffect(() => {
    if (userId) {
      setIsUpdate(true);
      // Fetch user data and populate form
      fetchData(`users/${userId}`)
        .then(user => {
          setEnterFirstName(user.firstname);
          setEnterEmail(user.email);
          setEnterMobile(user.mobile);
          setEnterRole(user.role);
          toast.success('User fetched successfully');
          // Password is usually not fetched or displayed for security reasons
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    } else {
      setIsUpdate(false);
      // Reset form fields for adding new user
      resetFields();
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      firstname: enterFirstname,
      email: enterEmail,
      password: enterPassword,
      mobile: enterMobile,
      role: enterRole,
    };

    try {
      if (isUpdate) {
        // Update user
        const response = await putData(`users/${userId}`, userData);
        onSuccess(response);
        toast.success('User updated successfully');
      } else {
        // Add user
        const response = await postData('users', userData);
        onSuccess(response);
        toast.success('User added successfully');
      }
      resetFields();
    } catch (error) {
      console.error('Error submitting user data:', error);
      toast.error('Failed to submit user data');
    }
  };
  function resetFields() {
    setIsUpdate(false);
    setEnterFirstName('');
    setEnterEmail('');
    setEnterPassword('');
    setEnterMobile('');
    setEnterRole('');
    
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="title mb"><Box>{isUpdate ? 'Update User' : 'Add User'}</Box></div>
      <FormGroup>
        <Label for="ufirstname">First Name</Label>
        <StyledInput
          type="text"
          id="ufirstname"
          placeholder="Enter first name"
          value={enterFirstname}
          onChange={(e) => setEnterFirstName(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="uemail">Email</Label>
        <StyledInput
          type="text"
          id="uemail"
          placeholder="Enter the email"
          value={enterEmail}
          onChange={(e) => setEnterEmail(e.target.value)}
          required
        />
      </FormGroup>
      {!isUpdate && (
        <FormGroup>
          <Label for="upassword">Password</Label>
          <StyledInput
            type="password"
            id="upassword"
            placeholder="Enter the password"
            value={enterPassword}
            onChange={(e) => setEnterPassword(e.target.value)}
            required
          />
        </FormGroup>
      )}
      <FormGroup>
        <Label for="umobile">Mobile</Label>
        <StyledInput
          type="number"
          id="umobile"
          placeholder="Enter the mobile"
          value={enterMobile}
          onChange={(e) => setEnterMobile(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="urole">Role</Label>
        <StyledSelect
          id="urole"
          placeholder="Enter the role"
          value={enterRole}
          onChange={(e) => setEnterRole(e.target.value)}
          required
        >
          <option value="">Select Category</option>
          <option value="vendor">Vendor</option>
          <option value="customer">Customer</option>
          <option value="admin">Admin</option>
          <option value="delivery">Delivery</option>
        </StyledSelect>
      </FormGroup>
      <StyledButton type="submit" color="primary">
        {isUpdate ? 'Update User' : 'Add User'}
      </StyledButton>
    </Form>
  );
};

export default CustomerForm;
