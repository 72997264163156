import React from 'react';
import useGetData from '../../hooks/useGetData'; // Import your custom hook
import { useParams } from 'react-router-dom';


const CategoryDetail = () => {
  const { id } = useParams();
  const { data: category, loading, error } = useGetData(`home/categories/${id}`);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container py-5">
      <h1>{category.label}</h1>
      <div className="row">
        {category.products.map(product => (
          <div key={product.id} className="col-md-4 mb-4">
            <div className="card">
              <img src={product.image} className="card-img-top" alt={product.name} />
              <div className="card-body">
                <h5 className="card-title">{product.name}</h5>
                <p className="card-text">${product.price}</p>
                <p className="card-text">{product.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryDetail;
