import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import Breadcrumb from './Breadcrumb';
import Pagination from './components/Pagination';
import useGetData from '../hooks/useGetData';
import { featuredProducts } from './components/shopData';
import { useCart } from '../context/CartContext';
import { toast } from 'react-toastify';

function Shop() {
  const title = 'Shop';
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [priceFilter, setPriceFilter] = useState(500); // Add state for price filter
  const [searchQuery, setSearchQuery] = useState(''); // Add state for search query

  const { data: categories, loading, error } = useGetData('home/categories');
  const { addToCart } = useCart(); // Get addToCart from useCart

  const getProductsFromCategories = (categories) => {
    return categories.reduce((acc, category) => {
      return acc.concat(category.products);
    }, []);
  };

  const allProducts = categories ? getProductsFromCategories(categories) : [];

  // Filter items based on selected category, price filter, and search query
  const filteredItems = selectedCategory 
    ? selectedCategory.products.filter(product => product.price <= priceFilter && product.name.toLowerCase().includes(searchQuery.toLowerCase()))
    : allProducts.filter(product => product.price <= priceFilter && product.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const currentItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory, priceFilter, searchQuery]); // Update page when category, price filter, or search query changes

  const handleCategorySelect = (category) => {
    setSelectedCategory(selectedCategory && selectedCategory.id === category.id ? null : category);
  };

  const handlePriceFilterChange = (e) => {
    setPriceFilter(Number(e.target.value));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAddToCart = (item) => {
    addToCart(item);
    //alert(`${item.name} has been added to the cart!`);
    toast.success(`${item.name} has been added to the cart!`);
  };

  // console.log("Categories:", categories);
  // console.log("Selected Category:", selectedCategory);
  // console.log("Filtered Items:", filteredItems);

  return (
    <>
      <Navbar />
      <Breadcrumb title={title} />
      <div className="container-fluid py-5">
        <div className="container">
          <h1 className="mb-4">Fresh Fruits Shop</h1>
          <div className="row g-4">
            <div className="col-lg-3">
              <div className="mb-4">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search for products..."
                  value={searchQuery} // Bind the input value to the state
                  onChange={handleSearchChange} // Update state on change
                />
              </div>
              {loading && <p>Loading categories...</p>}
              {error && <p>Error loading categories</p>}
              {!loading && !error && (
                <div className="mb-4">
                  <h4>Categories</h4>
                  <ul className="list-unstyled">
                    <li className={`d-flex justify-content-between ${!selectedCategory ? 'active' : ''}`}>
                      <button
                        className="btn btn-link text-decoration-none text-dark"
                        onClick={() => setSelectedCategory(null)}
                      >
                        Show All
                      </button>
                    </li>
                    {categories.map((category) => (
                      <li
                        key={category.id}
                        className={`d-flex justify-content-between ${selectedCategory && selectedCategory.id === category.id ? 'active' : ''}`}
                      >
                        <button
                          className="btn btn-link text-decoration-none text-dark"
                          onClick={() => handleCategorySelect(category)}
                        >
                          {category.label}
                        </button>
                        <span>({category.products.length})</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="mb-4">
                <h4>Filter by Price</h4>
                <input
                  type="range"
                  className="form-range"
                  min="0"
                  max="500"
                  value={priceFilter} // Bind the input value to the state
                  id="priceRange"
                  onChange={handlePriceFilterChange} // Update state on change
                />
                <div className="d-flex justify-content-between">
                  <span>$0</span>
                  <span id="priceValue">${priceFilter}</span> {/* Show current price filter value */}
                </div>
              </div>
              <div className="mb-4">
                <h4>Featured Products</h4>
                {featuredProducts.map((product, index) => (
                  <div key={index} className="d-flex mb-3">
                    <img src={product.img} alt={product.name} className="me-3" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                    <div>
                      <h6>{product.name}</h6>
                      <div className="d-flex">
                        {Array.from({ length: 5 }, (_, i) => (
                          <i key={i} className="fa fa-star text-warning"></i>
                        ))}
                      </div>
                      <p className="mb-0 text-primary">${product.price}</p>
                      {product.oldPrice && <small><del>${product.oldPrice}</del></small>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row g-4">
                {currentItems.map((item, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="card h-100 text-center">
                      <img src={item.image} className="card-img-top" alt={item.name} />
                      <div className="card-body">
                        <h5 className="card-title">{item.name}</h5>
                        <p className="card-text text-primary">${item.price}</p>
                      </div>
                      <div className="card-footer d-flex justify-content-around">
                        <button
                          onClick={() => handleAddToCart(item)}
                          className="btn btn-primary"
                        >
                          <i className="bi bi-cart"></i> Add to Cart
                        </button>
                        <Link to={`/shop/product/${item.id}`} className="btn btn-secondary"><i className="bi bi-eye"></i> View</Link> {/* Updated to Link */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Shop;
