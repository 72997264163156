import React, { useState, useEffect } from 'react';
import Box from '../components/box/Box';
import DashboardWrapper, { DashboardWrapperMain, DashboardWrapperRight } from '../components/dashboard-wrapper/DashboardWrapper';
import useGetData from '../hooks/useGetData';
import OrderItem from './cart/OrderItem';
import Modal from 'react-modal';
import styles from './Orders.module.scss';
import * as XLSX from 'xlsx';
import { putData } from '../redux/apiUtils';

const Orders = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);
    const [statusOptions] = useState(['Delivered', 'Cancelled', 'Pending']); // Add other statuses if needed
    const [cashOptions] = useState(['Cash', 'UPI', 'Pending']); // Add other statuses if needed

    // Use the hook
    const { data: ordersData ,refetch} = useGetData('orders');


    const openModal = (order) => {
        setSelectedOrder(order);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedOrder(null);
    };

    const handleStatusChange = async (orderId, newStatus) => {
        try {
            
            // Use putData from the hook to update the order status
            const response = await putData('orders/update-status', {
                id: orderId,
                status: newStatus,
            });

            if (response.ok) {
                // Update the order status locally
                setFilteredOrders(filteredOrders.map(order =>
                    order.id === orderId ? { ...order, order_status: newStatus } : order
                ));
               
            } else {
                // Handle errors
                console.error('Failed to update order status');
            }
        } catch (error) {
            console.error('Error updating order status:', error);
        }
        refetch();
    };

    const handlePaymentChange = async (orderId, newStatus) => {
        try {
            
            // Use putData from the hook to update the order status
            const response = await putData('orders/payment-status', {
                id: orderId,
                payment: newStatus,
            });

            if (response.ok) {
                // Update the order status locally
                setFilteredPayments(filteredPayments.map(order =>
                    order.id === orderId ? { ...order, payment: newStatus } : order
                ));
               
            } else {
                // Handle errors
                console.error('Failed to update order status');
            }
        } catch (error) {
            console.error('Error updating order status:', error);
        }
        refetch();
    };

    function processOrders(ordersData) {
        const ordersByOrderId = {};
        ordersData.forEach(order => {
            const orderId = order.id;
            if (!ordersByOrderId[orderId]) {
                ordersByOrderId[orderId] = {
                    id: orderId,
                    total: order.total,
                    order_status: order.order_status,
                    payment: order.payment,
                    form_data: order.form_data,
                };
            }
        });

        let totalOfAllOrders = 0;
        Object.values(ordersByOrderId).forEach(order => {
            totalOfAllOrders += order.total;
        });

        return { ordersByOrderId, totalOfAllOrders };
    }

    useEffect(() => {
        if (ordersData) {
            const { ordersByOrderId } = processOrders(ordersData);
            const orders = Object.values(ordersByOrderId);
            const filtered = orders.filter(order =>
                order.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
                `${order.form_data.first_name} ${order.form_data.last_name}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
                order.order_status.toLowerCase().includes(searchQuery.toLowerCase()) || order.payment.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredOrders(filtered);
        }
    }, [searchQuery, ordersData]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filteredOrders.map(order => ({
            'Order ID': order.id,
            'Customer': `${order.form_data.first_name} ${order.form_data.last_name}`,
            'Order': order.form_data.cart_items.map(p => p.product_name).join(', ') || 'N/A',
            'Address': order.form_data.address,
            'Total': order.total.toFixed(2),
            'Status': order.order_status,
            'Payment': order.payment,
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Orders');
        XLSX.writeFile(wb, 'Orders.xlsx');
    };

    const ordersContent = filteredOrders
        .map((order) => (
            <tr key={order.id}>
                <td>
                    <button className={styles.linkButton} onClick={() => openModal(order)}>
                        {order.id}
                    </button>
                </td>
                <td>{order.form_data.first_name} {order.form_data.last_name}</td>
                <td>{order.form_data.cart_items.map(p => p.product_name).join(', ') || 'N/A'}</td>
                <td>{order.form_data.address}</td>
                <td>${order.total.toFixed(2)}</td>
                <td>
                    <select
                        value={order.order_status}
                        onChange={(e) => handleStatusChange(order.id, e.target.value)}
                    >
                        {statusOptions.map(status => (
                            <option key={status} value={status}>{status}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <select
                        value={order.payment}
                        onChange={(e) => handlePaymentChange(order.id, e.target.value)}
                    >
                        {cashOptions.map(payment => (
                            <option key={payment} value={payment}>{payment}</option>
                        ))}
                    </select>
                </td>
            </tr>
        ))
        .reverse();

    return (
        <DashboardWrapper>
            <DashboardWrapperMain>
                <div className="row">
                    <div className="col-8 col-md-12">
                        <div className="row">
                            <div className="col-12 col-md-12 col-sm-12 mb">
                                <div className="title mb"><Box>List of Orders</Box></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Box>
                    <div className={styles.container}>
                        <h1 className={styles.title}>Order List</h1>
                        <div className={styles.header}>
                            <input
                                type="text"
                                placeholder="Search for order ID, customer, or status..."
                                className={styles.searchBar}
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{width: '50%'}}
                            />
                            <button className={styles.exportButton} onClick={exportToExcel}>Export as .xlsx</button>
                        </div>
                        <div className={styles.totalOrders}>
                            <strong>Total of All Orders: ${filteredOrders.reduce((acc, order) => acc + order.total, 0).toFixed(2)}</strong>
                        </div>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Customer</th>
                                    <th>Order</th>
                                    <th>Address</th>
                                    <th>Total</th>
                                    <th>Status</th>
                                    <th>Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersContent}
                            </tbody>
                        </table>
                        {selectedOrder && (
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Order Details"
                                className={styles.modal}
                                overlayClassName={styles.overlay}
                            >
                                <OrderItem order={selectedOrder} />
                                <button onClick={closeModal} className={styles.closeButton}>Close</button>
                            </Modal>
                        )}
                    </div>
                </Box>
            </DashboardWrapperMain>
            <DashboardWrapperRight>
                <p>Todo: add stats of orders</p>
            </DashboardWrapperRight>
        </DashboardWrapper>
    );
};

export default Orders;
