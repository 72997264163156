// src/redux/thunks/categoryThunks.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchData,
    //postData,
    putData,
   // deleteData,
} from '../apiUtils'; // Adjust the path as needed
import {
    fetchCategoriesStart,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
    updateProductSuccess,
} from '../slices/categorySlice';

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async (_, { dispatch }) => {
        dispatch(fetchCategoriesStart());
        try {
            const data = await fetchData('home/categories'); // Adjust the URL as needed
            dispatch(fetchCategoriesSuccess(data));
        } catch (error) {
            dispatch(fetchCategoriesFailure(error.message));
        }
    }
);

export const updateProduct = createAsyncThunk(
    'categories/updateProduct',
    async ({ categoryId, productId, field, value }, { dispatch }) => {
        try {
            await putData(`home/categories/${categoryId}/products/${productId}`, { [field]: value }); // Adjust URL as needed
            dispatch(updateProductSuccess({ categoryId, productId, field, value }));
        } catch (error) {
            console.error('Error updating product:', error);
        }
    }
);

export const updateCategories = createAsyncThunk(
    'categories/updateCategories',
    async (categories, { dispatch }) => {
        try {
            await putData('home/categories', categories); // Adjust URL as needed
        } catch (error) {
            console.error('Error updating categories:', error);
        }
    }
);
