import React, { useState, useEffect } from 'react';
import useGetData from '../../../hooks/useGetData'; // Adjust the path as needed
import { toast } from 'react-toastify';
import {  putData } from '../../../redux/apiUtils';

function ServicesInput() {
  const { data: services, loading, refetch, postData } = useGetData('home/service-items'); // Adjust route as needed
  const [items, setItems] = useState([]);

  // Update local state when services data is fetched
  useEffect(() => {
    if (services) {
      setItems(services);
    }
  }, [services]);

  // Handle input changes
  const handleChange = (id, field, value) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  // Handle image upload
  const handleImageUpload = async (id, file) => {
    if (!file) return;
  
    // Create a FormData object to hold the image data
    const formData = new FormData();
    formData.append('image', file);
  
    try {
      // Send the image to the server using the putData function
      const response = await putData(`home/service/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Handle the successful response (you can update UI here if needed)
      console.log('Image uploaded successfully:', response);
    } catch (error) {
      console.error('Failed to upload image:', error);
    }
    refetch();
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postData(items);
      toast.success('Services updated successfully');
      refetch(); // Optionally refetch the data to see the updates
    } catch (error) {
      console.error('Error submitting services:', error);
      toast.error('Failed to update services');
    }
  };

  return (
    <div className="settings-container">
      <h1>Our Services</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit} className="settings-form">
          <table className="services-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Subtitle</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {items.map((service) => {
                const imagePath = service.image;
                const imageUrl = imagePath
                  ? (imagePath.startsWith('http://') || imagePath.startsWith('https://') 
                    ? imagePath 
                    : process.env.PUBLIC_URL + '/' + imagePath)
                  : '';
                return (
                  <tr key={service.id}>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={service.image}
                        onChange={(e) => handleChange(service.id, 'image', e.target.value)}
                        disabled
                      />
                      <img src={imageUrl} alt={service.title} className="service-image" />
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleImageUpload(service.id, e.target.files[0])}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={service.title}
                        onChange={(e) => handleChange(service.id, 'title', e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={service.subtitle}
                        onChange={(e) => handleChange(service.id, 'subtitle', e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={service.link}
                        onChange={(e) => handleChange(service.id, 'link', e.target.value)}
                        disabled
                     />
                      <input
                        type="text"
                        className="form-control"
                        value={service.titleClass}
                        onChange={(e) => handleChange(service.id, 'titleClass', e.target.value)}
                        disabled
                      />
                      <input
                        type="text"
                        className="form-control"
                        value={service.bgClass}
                        onChange={(e) => handleChange(service.id, 'bgClass', e.target.value)}
                        disabled
                      />
                      <input
                        type="text"
                        className="form-control"
                        value={service.borderClass}
                        onChange={(e) => handleChange(service.id, 'borderClass', e.target.value)}
                        disabled
                      />
                      <input
                        type="text"
                        className="form-control"
                        value={service.contentBgClass}
                        onChange={(e) => handleChange(service.id, 'contentBgClass', e.target.value)}
                        disabled
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      )}
    </div>
  );
}

export default ServicesInput;
