import React, { useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import './Login.scss';
import { postData ,fetchUser} from '../../redux/apiUtils'; // Adjust the import path as necessary


function Login() {
  const title = 'Login';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      email: email,
      password: password
    };
  
    try {
      // Make login request
      const response = await postData('auth/login', formData);
      console.log('Login response:', response);
  
      if (response && response.token) {
        const token = response.token;
        console.log('Received token:', token);
  
        // Store token in localStorage
        localStorage.setItem('token', token);
  
        toast.success('Login Successful');
  
        // Fetch user information
        const userResponse = await fetchUser('users/details');
  
        console.log('User response:', userResponse);
  
        if (userResponse) {
          const user = userResponse;
  
          // Redirect based on user role
          switch (user.role) {
            case 'admin':
              navigate('/admin/dashboard');
              break;
            case 'customer':
              navigate('/');
              break;
            case 'vendor':
              navigate('/vendor');
              break;
            default:
              navigate('/');
          }
        } else {
          throw new Error('User information is missing.');
        }
      } else {
        throw new Error('Invalid login response.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Failed to login. Please try again.');
      setError(error.message);
    }
  };

  return (
    <>
      <Navbar />
      <Breadcrumb title={title} />
      <div className="container-fluid py-5">
        <div className="container py-5">
          <h1 className="mb-4">Login details</h1>
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleFormSubmit}>
            <div className="row g-5">
              <div className="col-md-12 col-lg-6 col-xl-7">
                <div className="form-item">
                  <label className="form-label my-3">Email Address<sup>*</sup></label>
                  <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-item">
                  <label className="form-label my-3">Password<sup>*</sup></label>
                  <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="form-check my-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="rememberMe">Remember me?</label>
                </div>
                <hr />
                <div className="form-item">
                  <button type="submit" className="btn btn-primary">Login</button>
                 
                </div>
                <div className="form-item mt-3">
                  <label className="form-check-label">
                    Create an Account? <Link to="/signup" className="signup-link">Signup</Link>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
