// src/api/apiUtils.js
import axios from 'axios';

// const BASE_URL = 'http://localhost:8080/api';
const BASE_URL = process.env.REACT_APP_BASE_BACKEND_URL;

// Helper function to get the token from localStorage
const getToken = () => localStorage.getItem('token');

export const getData = async (route) => {
    try {
      
        const response = await axios.get(`${BASE_URL}/${route}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        throw new Error(`An error occurred while fetching data: ${error.message}`);
    }
};

export const fetchData = async (route) => {
    try {
        const token = getToken();
        const response = await axios.get(`${BASE_URL}/${route}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        throw new Error(`An error occurred while fetching data: ${error.message}`);
    }
};

export const fetchUser = async (route) => {
    try {
        const token = getToken();
        const response = await axios.get(`${BASE_URL}/${route}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        throw new Error(`An error occurred while fetching data: ${error.message}`);
    }
};

export const postData = async (route, payload) => {
    try {
        const token = getToken();
        const response = await axios.post(`${BASE_URL}/${route}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        throw new Error(`An error occurred while posting data: ${error.message}`);
    }
};

export const putData = async (route, payload) => {
    try {
        const token = getToken();
        const response = await axios.put(`${BASE_URL}/${route}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200 || response.status === 204) {
            return response.data;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        throw new Error(`An error occurred while putting data: ${error.message}`);
    }
};

export const deleteData = async (route, id) => {
    try {
        const token = getToken();
        const response = await axios.delete(`${BASE_URL}/${route}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.status === 200 || response.status === 204) {
            return response.data;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        throw new Error(`An error occurred while deleting data: ${error.message}`);
    }
};
