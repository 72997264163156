import React from 'react'

const ServiceItem = ({ image, link, bgClass, borderClass, contentBgClass, title, subtitle, titleClass }) => (
    <div className="col-md-6 col-lg-4">
      <a href={link}>
        <div className={`service-item ${bgClass} rounded ${borderClass}`}>
          <img src={image} className="img-fluid rounded-top w-100" alt="" />
          <div className="px-4 rounded-bottom">
            <div className={`service-content ${contentBgClass} text-center p-4 rounded`}>
              <h5 className={titleClass}>{title}</h5>
              <h3 className="mb-0">{subtitle}</h3>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
export default ServiceItem